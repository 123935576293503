import React from 'react';
import PropTypes from 'prop-types';

const CardsforFrame = ({ Data }) => {
  const { title, subtitle, description, linkTitle, linkUrl } = Data;
  const isByLink = linkTitle.split(' ')[0] === 'By';
  const firstPart = linkTitle.split(' ')[0];
  const restLink = linkTitle.substring(linkTitle.indexOf(' '));
  return (
    <div className={'card_frame'}>
      <div className="card-body">
        {subtitle && <h5 className="sub-title">{subtitle}</h5>}
        {title && <h3 className="title">{title}</h3>}
        {description && <h6 className="description">{description}</h6>}
        {linkUrl &&
          (isByLink ? (
            <>
              <span className="link" style={{ textDecoration: 'none' }}>
                {firstPart}
              </span>
              <a href={linkUrl} className="link">
                {restLink}
              </a>
            </>
          ) : (
            <a href={linkUrl} className="link">
              {linkTitle}
            </a>
          ))}
      </div>
    </div>
  );
};

CardsforFrame.defaultProps = {
  subtitle: '96%',
  title: 'Lorem ipsum dolor sit amet.',
  description: '',
  backgroundColor: 'secondary',
  linkUrl: '#',
  linkTitle: 'Click here',
};

CardsforFrame.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  backgroundColor: PropTypes.oneOf(['primary', 'secondary']),
  linkUrl: PropTypes.string,
  linkTitle: PropTypes.string,
};

export default CardsforFrame;
